import React from 'react';

const PlaceHolder = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M8.534 5.236c-1.684 0-3.055 1.371-3.055 3.055 0 1.684 1.371 3.054 3.055 3.054 1.684 0 3.055-1.37 3.055-3.054 0-1.684-1.371-3.055-3.055-3.055zm0 5.237c-1.203 0-2.182-.979-2.182-2.182 0-1.203.979-2.182 2.182-2.182 1.203 0 2.182.979 2.182 2.182 0 1.203-.979 2.182-2.182 2.182z" transform="translate(-531 -2550) translate(531 2510) translate(0 35) translate(0 5)" />
            <path d="M14.374 2.451C12.794.871 10.692 0 8.457 0 6.22 0 4.12.87 2.54 2.451c-2.925 2.925-3.289 8.427-.788 11.76l6.705 9.683 6.694-9.669c2.511-3.347 2.148-8.85-.777-11.774zm.07 11.264l-5.987 8.646L2.46 13.7C.192 10.678.517 5.709 3.157 3.069 4.573 1.653 6.455.873 8.457.873s3.884.78 5.3 2.195c2.64 2.64 2.965 7.609.686 10.647z" transform="translate(-531 -2550) translate(531 2510) translate(0 35) translate(0 5)" />
        </g>
    </svg>
)

export default PlaceHolder;