import React, { Component } from 'react';
import './index.css'

class VirtualCardVideos extends Component {

    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default VirtualCardVideos;