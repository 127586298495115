import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Header from "./../common/Header";
import Footer from "./../common/Footer";
import DrawTimer from "./../common/DrawTimer";
import "./index.css";
import $ from "jquery";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import * as Constants from "./../common/constants";
import Cookies from "universal-cookie";

var popupText = "Error";
var popupHader = "Sorry!";
var rowCounter = 0;
var totalWithOne = 0;
//active Game Data variables
var activeGame = false;
var activeGameIndex = 0;
var activeText = "";

//Components
function GameDetailsPopUp(props) {
  totalWithOne = 0;
  function onButtonClick(text) {
    activeText = text;
    props.buttonClick();
  }
  props.liveScores.forEach(element => {
    if (element.my_score == 1) {
      totalWithOne = totalWithOne + 1;
    }
  });
  return (
    <div
      className={
        props.showSummry
          ? "fade show in game_center_summry_modal"
          : "modal fade"
      }
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-con" role="document">
        <div className="game_center_summry_body" id="details-modal">
          <div className="game_center_summry_header flex">
            <div className="col-md-6">
              <center>
                <div className="summry_header_sport_name">
                  {activeGame.name}
                </div>
                <button
                  className="summry_header_score_button"
                  onClick={e => onButtonClick("livescore")}
                >
                  Edit Live Scores
                </button>
              </center>
            </div>
            <div className="col-md-6">
              <center>
                <div className="summry_header_right">
                  <div className="summry_header_right_col">
                    <div className="summry_header_right_col_text">
                      Selected Teams
                    </div>
                    <div className="summry_header_right_col_score">
                      {props.myPicksCount}
                    </div>
                  </div>

                  <div className="summry_header_right_col">
                    <div className="summry_header_right_col_text">
                      Teams with 1 Run
                    </div>
                    <div className="summry_header_right_col_score">
                      {totalWithOne}
                    </div>
                  </div>
                </div>
              </center>
              <div />
            </div>
          </div>
          <div className="game_center_summry_body flex">
            <table className="summry-table">
              {(() => {
                if (props.myPicksCount > 0) {
                  return (
                    <>
                      <thead>
                        <tr>
                          <th>Team Name</th>
                          <th>Live Score</th>
                          <th>My Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.liveScores.map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.name}</td>
                              <td>{item.status}</td>
                              <td>{item.my_score}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  );
                } else {
                  return (
                    <>
                      <thead>
                        <tr>
                          <th>Team Name</th>
                          <th>Live Score</th>
                          <th>My Score</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {(() => {
                            if (activeGame == [] || activeGame == false) {
                              return (
                                <td colSpan="3" style={{ padding: "60px" }}>
                                  Click The Game Name In the Table To see Your
                                  Picks
                                </td>
                              );
                            } else if (activeGame.entries == "#") {
                              return (
                                <td colSpan="3" style={{ padding: "60px" }}>
                                  <a>No Selections Made</a>
                                </td>
                              );
                            } else {
                              return (
                                <td colSpan="3" style={{ padding: "60px" }}>
                                  <a onClick={e => onButtonClick("Pick Teams")}>
                                    Click Here to Pick Teams
                                  </a>
                                </td>
                              );
                            }
                          })()}
                        </tr>
                      </tbody>
                    </>
                  );
                }
              })()}
            </table>
            {(() => {
              let obj = props.prizeTable.find(
                obj => obj.no_of_team == props.meta.myPicksCount
              );
              let currPrize = 0;
              if (obj) {
                currPrize = obj.prize;
              }
              if (activeGame && props.meta.myPicksCount > 0) {
                let obj = props.prizeTable.find(
                  obj => obj.no_of_team == props.meta.myPicksCount
                );
                let currPrize = 0;
                if (obj) {
                  currPrize = obj.prize;
                }
                var mTotlaScore = 0;
                props.liveScores.forEach(element => {
                  if (element.my_score == 1) {
                    mTotlaScore = mTotlaScore + 1;
                  }
                });
                var text = "";
                var addLink = false;
                if (props.meta.gameStatus == "FINISHED") {
                  if (props.meta.result == "WON") {
                    addLink = true;
                    text =
                      "Congratulations!! You won " +
                      (activeGame.prize_type == "cash"
                        ? "$" + props.meta.prize
                        : props.meta.prize + " Points") +
                      "!. Your account balance has been updated";
                  } else {
                    text = "Sorry, you did not win this time.";
                    addLink = true;
                  }
                } else {
                  text =
                    "You could win up to " +
                    (activeGame.prize_type == "cash"
                      ? "$" + currPrize
                      : currPrize + " Points") +
                    " " +
                    activeGame.gametype_prize_text;
                }
                return (
                  <div>
                    <center>
                      <img
                        className="game_summry_results_img"
                        src={require("./../../assets/images/game-center/win.png")}
                      />
                      <div className="game_summry_results_text">{text}</div>
                    </center>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
function GamesTableLotteryRow(props) {}
class GameCentral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      show: false,
      games: [],
      user: [],
      balance: [],
      lotteryGames: [],
      balance: 0,
      liveScores: [],
      aciveFilters: ["", "", ""],
      prizeTable: [],
      meta: [],
      activeTab: 0,
      showDetails: false
    };
    this.onPickTeamsClicked = this.onPickTeamsClicked.bind(this);
    this.onPowerplayClicked = this.onPowerplayClicked.bind(this);
    this.onEnterMoreClicked = this.onEnterMoreClicked.bind(this);
    this.onGameNameClicked = this.onGameNameClicked.bind(this);
    this.onLiveScoreCliced = this.onLiveScoreCliced.bind(this);
    this.getJackpot = this.getJackpot.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onTabClicked = this.onTabClicked.bind(this);
  }
  getJackpot(prizeArray) {
    if (prizeArray) {
      prizeArray.sort((a, b) => parseFloat(a.hits) - parseFloat(b.hits));
      return "$" + prizeArray[prizeArray.length - 1].prize;
    } else {
      return "Coming soon";
    }
  }
  onLiveScoreCliced() {
    this.onPickTeamsClicked(
      activeGame.gametype_name,
      activeGame.stat_time,
      activeGame,
      activeText
    );
  }
  handleClose() {
    this.setState({
      show: false
    });
  }

  updateBalance = ball => {
    this.setState({ balance: ball });
  };

  onTabClicked(tabId) {
    this.setState({
      activeTab: tabId
    });
  }
  getStringDate(mDate) {
    if (mDate == null) {
      return "";
    }
    var mydate = new Date(mDate);
    var month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ][mydate.getMonth()];
    var str =
      month + " " + mydate.getDate() + ", " + mydate.getFullYear() + " ";
    return str;
  }
  handleShow() {
    this.setState({
      show: true
    });
  }
  onPickTeamsClicked(gametype, dateTime, game, action) {
    if (action == "Pick Teams" || action == "Edit Picks") {
      var path = "/select-teams-cta";
      if (gametype == "CTA") path = "/select-teams-cta";
      if (gametype == "Pick 5") path = "/select-teams-pick5";
      if (gametype == "Zones") path = "/select-teams-zones";
      if (gametype == "PowerPlay") path = "/select-teams-powerplay";
      if (gametype == "High 5") path = "/select-teams-high5";
      if (gametype == "Brackets") path = "/select-teams-brackets";

      if (dateTime == "Waiting For Players") {
        popupText =
          "Cannot perform action untill the required number of players join";
        popupHader = "Sorry!";
        this.handleShow();
        return;
      }
      this.props.history.push({
        pathname: path,
        state: {
          date: dateTime,
          gameData: game
        }
      });
    } else {
      this.props.history.push({
        pathname: "/livescore",
        state: {
          game: game,
          allGames: this.state.games
        }
      });
    }
  }
  onPickBallsClicked(gametype, dateTime, game, action) {
    var path = "";
    if (gametype == "747") path = "/747";
    if (gametype == "Sweet 16") path = "/sweet16";
    if (gametype == "Elite 8") path = "/elite8";
    if (gametype == "Gridlock") path = "/gridlock";
    if (action == "live") {
      path = path + "-draw";
    }
    this.props.history.push({
      pathname: path,
      state: {
        date: dateTime,
        gameData: game
      }
    });
  }
  onPowerplayClicked() {
    if (!activeGame) {
      popupText = "Please Select A Game To Edit";
      popupHader = "Sorry!";
      this.handleShow();
    } else {
      this.props.history.push({
        pathname: "/livescore",
        state: {
          score: this.state.liveScores,
          game: activeGame,
          prize: this.state.prizeTable,
          allGames: this.state.games,
          activeGameIndex: activeGameIndex
        }
      });
      activeGame = false;
    }
  }
  onEnterMoreClicked() {
    this.props.history.push({
      pathname: "/live-sports"
    });
  }
  setDroDown(event) {
    const target = event.target;
    if (target) {
      const distanceFromTableTop =
        $(target).offset().top - $(".game_center_table").offset().top;
      console.log(distanceFromTableTop);
      const canDropUp = 110 < distanceFromTableTop;
      console.log(canDropUp);
      $(".dropdown-menu").toggleClass("menu_up", canDropUp);
    }
  }
  onGameNameClicked(index, name) {
    if (name == "NFL Power Draft") {
      this.props.history.push({
        pathname: "/draft-day"
      });
      return;
    }
    const cookies = new Cookies();
    const jwt = cookies.get("jwt");
    activeGame = this.state.games[index];
    activeGameIndex = index;
    var entry = this.state.games[index].id;
    var league = this.state.games[index].league_id;
    var that = this;
    fetch(
      "https://" +
        Constants.URL +
        "/public_api/entry/game_data.php?prize_id=" +
        activeGame.prize_id +
        "&jwt=" +
        jwt +
        "&entry=" +
        entry +
        "&league=" +
        league
    )
      .then(res => res.json())
      .then(
        result => {
          that.setState({
            prizeTable: result.prizes,
            liveScores: result.scores,
            meta: result.meta,
            showDetails: true
          });
        },
        error => {
          that.setState({
            error: error
          });
        }
      );
  }

  onFilterApplied(index, text) {
    var filters = this.state.aciveFilters.slice();
    filters[index] = text;
    this.setState({
      aciveFilters: filters
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const cookies = new Cookies();
    const jwt = cookies.get("jwt");
    var that = this;
    window.addEventListener("click", function(e) {
      var mod = document.getElementById("details-modal");
      if (mod != null) {
        if (document.getElementById("details-modal").contains(e.target)) {
        } else {
          that.setState({
            showDetails: false
          });
        }
      }
    });
    fetch(
      "https://" +
        Constants.URL +
        "/public_api/entry/readmygames.php?jwt=" +
        jwt
    )
      .then(res => res.json())
      .then(
        xx => {
          console.log(xx.records);
          this.setState({
            games: xx.records,
            lotteryGames: xx.lottery_games,
            user: xx.user,
            balance: xx.balance
          });
        },
        error => {
          this.setState({
            error: error
          });
        }
      );
  }
  setDropDirection(menuClassName) {
    const canDropDown = this.menuHeight < this.distanceFromBottom;
    this.elememt.toggleClass("dropdown", canDropDown);
    this.elememt.toggleClass("dropup", !canDropDown);
  }

  distanceFromBottom() {}
  //Componetns
  componentTableMobile() {
    if (this.state.activeTab == 0) {
      return (
        <>
          {this.state.games.map((data1, key) => {
            return (
              <div className="game-center-mobile-box">
                <div className="mobile-box-row">
                  <div className="mobile-box-row-left">Game Name</div>
                  <div className="mobile-box-row-right">
                    <a
                      className="c-p"
                      onClick={() => this.onGameNameClicked(key, data1.name)}
                    >
                      {data1.name}
                    </a>
                  </div>
                </div>
                <div className="mobile-box-row">
                  <div className="mobile-box-row-left">Game Type</div>
                  <div className="mobile-box-row-right">
                    {data1.gametype_name}
                  </div>
                </div>
                <div className="mobile-box-row">
                  <div className="mobile-box-row-left">Status</div>
                  <div className="mobile-box-row-right">{data1.status}</div>
                </div>
                <div className="mobile-box-row">
                  <div className="mobile-box-row-left">Start Date</div>
                  <div className="mobile-box-row-right">{data1.stat_time}</div>
                </div>
                <div className="mobile-box-row">
                  <div className="mobile-box-row-left">Max Prize</div>
                  <div className="mobile-box-row-right">
                    {" "}
                    {data1.prize_type == "cash"
                      ? "$" + data1.prize
                      : data1.prize + " Pts."}
                  </div>
                </div>
                <div className="mobile-box-row">
                  <div className="mobile-box-row-left">Entries</div>
                  <div className="mobile-box-row-right"> {data1.entries}</div>
                </div>
                <div className="mobile-box-row">
                  {this.componentTableAction(data1, key)}
                </div>
              </div>
            );
          })}
        </>
      );
    }
  }
  componentTableDesktop() {
    return (
      <div className="game-center-table">
        <div className="game-center-table-thead">
          <div className="game-center-table-tr">
            <div className="game-center-table-th"> Game</div>
            <div className="game-center-table-th">
              {" "}
              <div className="dropdown">
                <span className="drop-tab" data-toggle="dropdown">
                  {this.state.aciveFilters[0] == ""
                    ? "Game Type"
                    : this.state.aciveFilters[0]}
                  <span className="caret" />
                </span>
                <ul className="dropdown-menu progrs-list">
                  <li>
                    <a onClick={() => this.onFilterApplied(0, "")}>All</a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(0, "Chase The Ace")}>
                      Chase The Ace
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(0, "Pick 5")}>
                      Pick 5
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(0, "High 5")}>
                      High 5
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(0, "Powerplay")}>
                      Powerplay
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(0, "Power Draft")}>
                      Power Draft
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="game-center-table-th">
              {" "}
              <div className="dropdown">
                <span className="drop-tab" data-toggle="dropdown">
                  {this.state.aciveFilters[1] == ""
                    ? "Status"
                    : this.state.aciveFilters[1]}
                  <span className="caret" />
                </span>
                <ul className="dropdown-menu progrs-list">
                  <li>
                    <a onClick={() => this.onFilterApplied(1, "")}>All</a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(1, "In Progress")}>
                      In Progress
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(1, "Not Started")}>
                      Not Started
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(1, "Finished")}>
                      Finished
                    </a>
                  </li>
                  <li>
                    <a onClick={() => this.onFilterApplied(1, "Archived")}>
                      Archived
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="game-center-table-th"> Start Date</div>
            <div className="game-center-table-th"> Max Prize</div>
            <div className="game-center-table-th"> Entries</div>
            <div className="game-center-table-th"> Actions</div>
          </div>
        </div>
        <div className="game-center-table-tbody">
          {this.state.activeTab == 0
            ? this.state.games.map((data1, key) => {
                var filters = this.state.aciveFilters.slice();

                var shouldShowGame = true;
                if (filters[0] != "" && data1.gametype_name != filters[0]) {
                  shouldShowGame = false;
                }
                if (filters[1] != "" && data1.status != filters[1]) {
                  shouldShowGame = false;
                }
                if (filters[1] == "" && data1.status == "Archived") {
                  shouldShowGame = false;
                }
                if (filters[2] != "") {
                  if (filters[2] == "Edit Picks" && !data1.has_picks) {
                    shouldShowGame = false;
                  }
                  if (filters[2] == "Pick Teams" && data1.has_picks) {
                    shouldShowGame = false;
                  }
                }
                if (shouldShowGame) {
                  rowCounter = rowCounter + 1;
                  var text = "";
                  if (data1.status == "Finished") {
                    text = "View Results";
                  } else {
                    if (data1.has_picks) {
                      text =
                        data1.status == "In Progress"
                          ? "Edit Scores"
                          : "Edit Picks";
                    } else {
                      text = "Pick Teams";
                    }
                  }

                  return (
                    <div className="game-center-table-tr" key={key}>
                      {" "}
                      <div className="game-center-table-td">
                        <a
                          className="c-p"
                          onClick={() =>
                            this.onGameNameClicked(key, data1.name)
                          }
                        >
                          {data1.name}
                        </a>
                      </div>
                      <div className="game-center-table-td">
                        <p>{data1.gametype_name}</p>
                      </div>
                      <div className="game-center-table-td">
                        <p>{data1.status}</p>
                      </div>
                      <div className="game-center-table-td">
                        <p>{data1.stat_time}</p>
                      </div>
                      <div className="game-center-table-td">
                        <p>
                          {data1.prize_type == "cash"
                            ? "$" + data1.prize
                            : data1.prize + " Pts."}
                        </p>
                      </div>
                      <div className="game-center-table-td">
                        {data1.entries}
                      </div>
                      <div className="game-center-table-td">
                        {this.componentTableAction(data1, key)}
                      </div>
                    </div>
                  );
                }
              })
            : this.state.lotteryGames.map((data1, key) => {
                var filters = this.state.aciveFilters.slice();

                var shouldShowGame = true;
                if (filters[0] != "" && data1.gametype_name != filters[0]) {
                  shouldShowGame = false;
                }
                if (filters[1] != "" && data1.status != filters[1]) {
                  shouldShowGame = false;
                }
                if (filters[1] == "" && data1.status == "Archived") {
                  shouldShowGame = false;
                }

                if (shouldShowGame) {
                  rowCounter = rowCounter + 1;
                  return (
                    <tr key={key}>
                      <div className="game-center-table-td">
                        <a className="c-p">{data1.name}</a>
                      </div>
                      <div className="game-center-table-td">
                        <p>{data1.gametype_name}</p>
                      </div>
                      <div className="game-center-table-td">
                        <p>{data1.status}</p>
                      </div>

                      <div className="game-center-table-td">
                        <p>{data1.start_datetime}</p>
                      </div>
                      <div className="game-center-table-td">
                        <p>{this.getJackpot(data1.prize)}</p>
                      </div>
                      <div className="game-center-table-td"> --</div>
                      <div className="game-center-table-td">
                        <div class="dropdown dropleft">
                          <img
                            class="btn btn-secondary dropdown-toggle"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            src={require("./../../assets/images/game-center/dots.png")}
                            onClick={this.setDroDown}
                          />

                          <div
                            class={
                              rowCounter > 1
                                ? "dropdown-menu game_center_action_menu menu_up short"
                                : "dropdown-menu game_center_action_menu short"
                            }
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              className="dropdown-item action_menu_item"
                              onClick={() =>
                                this.onPickBallsClicked(
                                  data1.name,
                                  data1.start_datetime,
                                  data1,
                                  "live"
                                )
                              }
                            >
                              <img
                                className="action_menu_item_img"
                                src={require("./../../assets/images/game-center/group_19.png")}
                              />
                              <div className="action_menu_item_text">
                                Edit Live Draw
                              </div>
                            </div>

                            <div
                              className="dropdown-item action_menu_item"
                              onClick={() =>
                                this.onPickBallsClicked(
                                  data1.name,
                                  data1.start_datetime,
                                  data1,
                                  "Pick Teams"
                                )
                              }
                            >
                              <img
                                className="action_menu_item_img"
                                src={require("./../../assets/images/game-center/group_19_2.png")}
                              />
                              <div className="action_menu_item_text">
                                Show my Picks
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tr>
                  );
                }
              })}
        </div>
      </div>
    );
  }

  componentTableAction(data1, key) {
    return (
      <>
        {data1.gametype_name == "Power Draft" ? (
          <>
            <button
              className="button_pick_teams"
              onClick={() => this.props.history.push("/draft-day-teams")}
            >
              {" "}
              Edit Picks
            </button>
            <button
              className="button_goto_game"
              onClick={() => this.props.history.push("/draft-day-live")}
            >
              {" "}
              Go To Game
            </button>
          </>
        ) : data1.has_picks ? (
          <div class="dropdown dropleft">
            <img
              class="btn btn-secondary dropdown-toggle"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              src={require("./../../assets/images/game-center/dots.png")}
            />

            <div
              class={
                rowCounter > 1
                  ? data1.match_started
                    ? "dropdown-menu game_center_action_menu menu_up_short"
                    : "dropdown-menu game_center_action_menu menu_up"
                  : "dropdown-menu game_center_action_menu"
              }
              aria-labelledby="dropdownMenuButton"
            >
              <div
                className="dropdown-item action_menu_item"
                onClick={() =>
                  this.onPickTeamsClicked(
                    data1.gametype_name,
                    data1.stat_time,
                    data1,
                    "Edit Live Score"
                  )
                }
              >
                <img
                  className="action_menu_item_img"
                  src={require("./../../assets/images/game-center/group_19.png")}
                />
                <div className="action_menu_item_text">Edit Live Scores</div>
              </div>
              <>
                {data1.match_started ? (
                  ""
                ) : (
                  <div
                    className="dropdown-item action_menu_item"
                    onClick={() =>
                      this.onPickTeamsClicked(
                        data1.gametype_name,
                        data1.stat_time,
                        data1,
                        "Pick Teams"
                      )
                    }
                  >
                    <img
                      className="action_menu_item_img"
                      src={require("./../../assets/images/game-center/group_19_2.png")}
                    />
                    <div className="action_menu_item_text">
                      {data1.has_picks ? "Show my Picks" : "Pick Teams"}
                    </div>
                  </div>
                )}
              </>

              <div
                className="dropdown-item action_menu_item"
                onClick={() => this.onGameNameClicked(key)}
              >
                <img
                  className="action_menu_item_img"
                  src={require("./../../assets/images/game-center/group_19_3.png")}
                />
                <div className="action_menu_item_text">Show My Scores</div>
              </div>
            </div>
          </div>
        ) : (
          <button
            className="button_pick_teams"
            onClick={() =>
              this.onPickTeamsClicked(
                data1.gametype_name,
                data1.stat_time,
                data1,
                "Pick Teams"
              )
            }
          >
            Pick My Teams
          </button>
        )}
      </>
    );
  }
  render() {
    totalWithOne = 0;
    rowCounter = 0;
    this.state.liveScores.forEach(element => {
      if (element.my_score == 1) {
        totalWithOne = totalWithOne + 1;
      }
    });
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title> {popupHader} </Modal.Title>
          </Modal.Header>
          <Modal.Body> {popupText}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <GameDetailsPopUp
          showSummry={this.state.showDetails}
          liveScores={this.state.liveScores}
          myPicksCount={this.state.meta.myPicksCount}
          prizeTable={this.state.prizeTable}
          meta={this.state.meta}
          buttonClick={this.onLiveScoreCliced}
        />

        <Header />
        <DrawTimer />
        <div>
          <div className="game_center">
            <div className="game_center_user">
              {/* <div className="game_center_user_name">
                  {this.state.user.dname}
                </div>
                <div className="game_center_user_date">
                  {"Member since " +
                    this.getStringDate(this.state.user.join_date)}
                </div> */}
            </div>
            <div className="game_center_first_row">
              <div className="game_center_first_row_item">
                <center>
                  <div style={{ marginTop: "7px" }}>
                    <img
                      className="game_center_first_row_img"
                      src={require("./../../assets/images/game-center/group_21.png")}
                    />
                    <div className="game_center_item_details">
                      <div className="game_center_item_details_header">
                        {this.state.balance.cash == null
                          ? "$00"
                          : "$" + this.state.balance.cash}
                      </div>
                      <div className="game_center_item_details_sub">
                        Your Total Winnings
                      </div>
                    </div>
                  </div>
                </center>
              </div>
              {/* <div className="game_center_first_row_item">
                  <center>
                    <div style={{ width: "155px", marginTop: "7px" }}>
                      <img
                        className="game_center_first_row_img"
                        src={require("./../../assets/images/game-center/group_21_2.png")}
                      />
                      <div className="game_center_item_details">
                        <div className="game_center_item_details_header">
                          {this.state.user.points === undefined
                            ? "00"
                            : this.state.user.points === null
                            ? "0000"
                            : this.state.user.points.toLocaleString()}
                        </div>
                        <div className="game_center_item_details_sub">
                          Pts Collected
                        </div>
                      </div>
                    </div>
                  </center>
                </div> */}
              {/* <div className="game_center_first_row_item">
                  <center>
                    <div style={{ width: "155px", marginTop: "7px" }}>
                      <img
                        className="game_center_first_row_img"
                        src={require("./../../assets/images/game-center/group_21_3.png")}
                      />
                      <div className="game_center_item_details">
                        <div className="game_center_item_details_header">
                          {this.state.user.club === undefined
                            ? "Basic"
                            : this.state.user.points === null
                            ? "Basic"
                            : this.state.user.club}
                        </div>
                        <div className="game_center_item_details_sub">
                          Player Club
                        </div>
                      </div>
                    </div>
                  </center>
                </div> */}
                <div className = "game-center-redeem-wrapper"></div>
              <button
                className="game_center_first_row_button"
                onClick={() =>
                  this.props.history.push("/my-account/my-balance")
                }
              >
                Redeem Now!
              </button>
            </div>
            <div className="game_center_table_wrapper">
              <div className="game_center_table_header">My Game Summary</div>
              <div className="game_center_table_outer">
                <div className="game-center-tabs">
                  <div
                    onClick={() => this.onTabClicked(0)}
                    className={
                      this.state.activeTab == 0
                        ? "game-center-tab active"
                        : "game-center-tab"
                    }
                  >
                    LIVE SPORTS GAMES
                  </div>
                  <div
                    onClick={() => this.onTabClicked(1)}
                    className={
                      this.state.activeTab == 1
                        ? "game-center-tab active"
                        : "game-center-tab"
                    }
                  >
                    LOTTO GAMES
                  </div>
                </div>
                <div className="table-desktop">
                  {this.componentTableDesktop()}
                </div>
                <div className="table-mobile">
                  {this.componentTableMobile()}
                </div>
              </div>
            </div>

            <div className = "game_center_enter_more_wrapper">
              <div className="game_center_enter_more">
                <div>
                  <img
                    src={require("./../../assets/images/game-center/scoreboard.png")}
                  />
                  <p className="explore_text">
                    Want to explore more live sports games ?
                  </p>
                </div>

                <button
                  onClick={this.onEnterMoreClicked}
                  className="game_center_more_games_button"
                >
                  Go to Powerplay Live Sports
                </button>
              </div>
              <div className="game_center_enter_more">
                <div>
                  <img
                    src={require("./../../assets/images/game-center/lottoicon.png")}
                  />
                  <p className="explore_text">
                    Want to explore more Lotto games ?
                  </p>
                </div>

                <button
                  onClick={() => this.props.history.push("/powerplay-lotto")}
                  className="game_center_more_games_button"
                >
                  Go to Powerplay Lotto
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(GameCentral);
