export const LEAGUE_ID_NHL = 14;
export const LEAGUE_ID_MLB = 15;
export const LEAGUE_ID_NFL = 16;
export const LEAGUE_ID_NBA = 17;

export const HOCKEY_ID = 1;
export const BASEBALL_ID = 2;
export const BASKETBALL_ID = 3;
export const FOOTBALL_ID = 4;

export const GAMETYPE_ID_CTA = 44;
export const GAMETYPE_ID_POWERPLAY = 49;
export const GAMETYPE_ID_PICK5 = 45;
export const GAMETYPE_ID_ZONES = 46;
export const GAMETYPE_ID_HIGH5 = 48;
export const GAMETYPE_ID_DRAFT_DAY = 54;

export const LOTTO_747_ID = 1;
export const SWEET16_ID = 2;
export const ELITE8_ID = 3;
export const GRIDLOCK_ID = 4;

export const LOTETRY_POWERPLAY_REFRESH = 1;
export const LOTETRY_POWERPLAY_CHANGE = 2;
export const LOTETRY_POWERPLAY_FORCE_MATCH = 3;
export const LOTETRY_POWERPLAY_INCREASE = 4;
export const LOTETRY_POWERPLAY_DECREASE = 5;

export const SPORTS_POWERPLAY_INCREASE = 19;
export const SPORTS_POWERPLAY_DESCREASE = 18;
export const SPORTS_POWERPLAY_UNDO = 21;
export const SPORTS_POWERPLAY_11_TO_1 = 13;
export const SPORTS_POWERPLAY_SWAP = 24;
export const SPORTS_POWERPLAY_LOCK = 1;

export const DRAFT_POWERPLAY_UP = 1;
export const DRAFT_POWERPLAY_DOWN = 2;
export const DRAFT_POWERPLAY_SWAP = 3;
export const DRAFT_POWERPLAY_UNDO = 4;

export const ENK = 1122334455;
export const URL_WEBSITE = "powerplaysystems.com";
export const URL = "test.powerplaysystems.com";

export var DEMO_PICKS = [];
export var DEMO_DRAW = [];
export var DEMO_GAME_DATA = {
  id: 122,
  game_type: 1,
  start_datetime: "2019-12-30 12:59:00",
  prize_id: 44,
  prize: [
    { hits: 7, prize: 1000 },
    { hits: 6, prize: 400 },
    { hits: 5, prize: 200 },
    { hits: 4, prize: 100 },
    { hits: 3, prize: 50 },
    { hits: 2, prize: 25 },
    { hits: 1, prize: 15 }
  ],
  countdown_timer: 30,
  delay: 1,
  game_text: "1",
  rules: "undefined",
  odds_text: "1 in 10,000",
  code: null,
  status: "unplayed",
  deadline: "2019-12-30 12:59:00",
  entry: 109
};
